
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "CustomDialog",
  components: {},
})
export default class CustomDialog extends Vue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop({ default: "" }) dialogTitle: any;
  @Prop({ default: "Yes" }) confirmText: string;
  @Prop({ default: "No" }) cancelText: string;
  @Prop({ default: true }) disableConfirm: boolean;
  @Prop({ default: false }) isDownloadInprogress: boolean;
  @Prop({ default: false }) showProgress: boolean;
  @Prop({ default: 0 }) progressPercentage: number;

  handleCloseIcon(): void {
    if (this.isDownloadInprogress) {
      this.$message("The download will continue in the background");
    }
    this.handleClose();
  }

  handleClose(): void {
    this.$emit("closeDialog", true);
  }

  handleConfirm(): void {
    this.$emit("confirmDialog", true);
  }
}
