
import { DownloadUtils } from "@/shared/utils/DownloadUtils";
import { forkJoin, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { Prop, Component, Vue, Watch } from "vue-property-decorator";
import CustomDialog from "../CustomDialog/CustomDialog.vue";
import "./DownloadReports.scss";
@Component({
  name: "DownloadReports",
  components: {
    CustomDialog,
  },
})
export default class DownloadReports extends Vue {
  @Prop() filePathArr;
  @Prop() userId;
  @Prop() executionId;
  @Prop() itemId;

  showDialog = true;
  showDownloadingDialog = true;
  showFilesForDownload = false;
  showProgress = false;
  progressPercentage = 0;

  selectedFilesForDownload = [];
  fileSizeInfoArr: any[] = [];
  fileDownloadLoading = false;
  isDownloadInprogress = false;
  showError = false;
  errorMsg = "";
  selectAllFiles = false;

  @Watch("selectedFilesForDownload")
  setSelectAll(value: any): void {
    this.selectAllFiles = value.length === this.filePathArr.length;
  }

  get dialogTitle(): string {
    return this.isDownloadInprogress ? "Downloading" : "File Download";
  }

  getfileName(filePath: string): string {
    if (filePath.length > 0) {
      const fileName = filePath.split("/");
      return fileName[fileName.length - 1];
    }
    return "";
  }

  getfileSize(filePath: string): string {
    if (filePath === undefined) return "";
    return this.fileSizeInfoArr.find((item) => item.filePath === filePath)
      .fileSize;
  }

  handleCheckAllChange(val: boolean): void {
    this.selectedFilesForDownload = val ? this.filePathArr : [];
  }

  displayErrorReport(errorMsg: string): void {
    this.showError = true;
    this.errorMsg = errorMsg;
  }

  async created(): Promise<any> {
    const fileNotFoundforDownload =
      this.filePathArr.length === 0 || this.filePathArr[0].length === 0;
    if (fileNotFoundforDownload)
      return this.displayErrorReport("No file paths available for download");
    this.setFilePathSizeInfo();
  }

  /**
   * makes http HEAD requests to get the filesize of all the files to be shown in popup
   * @returns Promise
   */
  async setFilePathSizeInfo(): Promise<any> {
    this.fileDownloadLoading = true;
    const fileSizeObs$ = DownloadUtils.getFileSizeforFilePaths(
      this.filePathArr
    );
    fileSizeObs$.subscribe({
      next: (filesResponse) => {
        if (filesResponse?.data.code === "1") {
          filesResponse.data.data.map((res) => {
            this.fileSizeInfoArr.push({
              filePath: res.filePath,
              fileSize: this.getFileSizeinKilobyte(+res.fileSize),
            });
          });
        }
        this.fileDownloadLoading = false;
        this.showFilesForDownload = this.fileSizeInfoArr.length > 0;
      },
      error: (error) => {
        this.fileDownloadLoading = false;
        this.showError = true;
        this.errorMsg = "Error in fetching reports";
      },
    });
  }

  /**
   * utility function to convert the filesize in bytes to kilobytes
   * @param { number} filesize: filesize in bytes
   * @returns string
   */
  getFileSizeinKilobyte(fileSize: number): string {
    return Math.ceil(fileSize / 1024) + "kb";
  }

  /**
   * Function to close the dialog popup
   * @returns void
   */
  closeFileDownload(): void {
    if (this.isDownloadInprogress) {
      this.$store.commit("hideDownloadingDialog");
    } else {
      this.showDialog = false;
      this.$emit("closeDownloadReports", false);
    }
  }

  /**
   * Function to confirm and download the selected files
   * @returns void
   */
  confirmFileDownload(): void {
    const fileObs$ = DownloadUtils.getDownloadFileDataObs(
      this.userId,
      this.selectedFilesForDownload
    );
    const itemID = this.$route.query?.id || this.itemId;
    const executionID = this.$route.query?.executionId || this.executionId;
    const zipFileName = `${itemID}_${executionID}`;

    let totalFiles = this.selectedFilesForDownload.length;
    let filesDownloaded = 0;

    this.showProgress = true;
    this.isDownloadInprogress = true;

    const progressObs$ = fileObs$.map((file$) => {
      return new Observable((observer) => {
        file$.subscribe({
          next: (progress) => {
            if (progress.progress === 100) {
              filesDownloaded++;
              this.progressPercentage = Math.round(
                (filesDownloaded / totalFiles) * 100
              );
            }
            observer.next(progress);
          },
          error: (error) => {
            observer.error(error);
          },
          complete: () => {
            observer.complete();
            this.handleCheckAllChange(false);
          },
        });
      });
    });

    forkJoin(progressObs$)
      .pipe(
        finalize(() => {
          // Added a delay so user can see 100% on progress
          setTimeout(() => {
            this.isDownloadInprogress = false;
            this.showProgress = false;
          }, 1000);
        })
      )
      .subscribe({
        next: (responses) => {
          const blobs = responses.map((response: any) => response);
          if (blobs.length > 0) {
            DownloadUtils.downloadReportFiles(zipFileName, blobs);
          } else {
            this.errorMsg = "No files to download";
            this.showError = true;
          }
        },
        error: (error) => {
          this.fileDownloadLoading = false;
          this.showError = true;
          if (error.message.includes("status code 404")) {
            const errorMsgs: string[] = [];
            this.selectedFilesForDownload.forEach((fileUrl: string) => {
              const [fileName] = fileUrl.split("/").slice(-1);
              if (fileName) {
                errorMsgs.push(fileName);
              }
            });
            this.errorMsg = errorMsgs.join(", ") + " doesn't exist";
          } else {
            this.errorMsg = "Error in downloading reports";
          }
        },
      });
  }
}
